import {JSONValue} from '@backstage-components/base';
import {SerializedStyles} from '@emotion/utils';
import {IButtonProps} from './ButtonComponent';

type ButtonStyles = Pick<
  IButtonProps,
  | 'background'
  | 'typography'
  | 'spacing'
  | 'border'
  | 'sizing'
  | 'cssProp'
  | 'ignorePointerEvents'
  | 'hoverColor'
>;

type Styles = Record<string, JSONValue | undefined | SerializedStyles>;

export const sharedBtnStyles = ({
  background,
  typography,
  spacing,
  border,
  sizing,
  cssProp,
  ignorePointerEvents,
  hoverColor,
}: ButtonStyles): Styles => {
  return {
    css: cssProp,
    h: sizing?.height,
    w: sizing?.width,
    bg: background?.buttonColor,
    color: typography?.textColor,
    fontSize: typography?.fontSize,
    fontFamily: typography?.fontFamily,
    borderColor: border?.borderColor ?? 'inherit',
    borderWidth: border?.borderWidth ?? '0',
    borderStyle: border?.borderStyle ?? 'none',
    borderRadius: border?.borderRadius ?? '0.375rem',
    padding: spacing?.padding,
    margin: spacing?.margin,
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: 'none',
    // this is disable interactions in the 'classic' editor
    pointerEvents: ignorePointerEvents ? 'none' : 'initial',
    // this is to remove text-decoration from the link on hover without overriding default hover styles
    textDecoration: 'none !important',
    _active: {opacity: 1, color: '#fff'},
    _focusVisible: {outline: '#006aeb auto 1px'},
    _focus: {outline: 'none'},
    _hover: {
      background: hoverColor ?? 'initial',
    },
  };
};
