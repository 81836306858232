import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  animationDescription,
  ComponentAdmin,
  OptionalString,
  SubscribesTo,
  PublishesTo,
  ModuleCategory,
  spacingSchema,
  sizingSchema,
  typographySchema,
  borderSchema,
} from '@backstage-components/base';

import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Button';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'component';

export const schema = Type.Object(
  {
    children: OptionalString('Button Label'),
    ariaLabel: OptionalString('Button ARIA Label'),
    href: OptionalString('Link Url'),
    background: Type.Optional(
      Type.Object({
        buttonColor: OptionalString('Button Color'),
      })
    ),
    spacing: Type.Optional(spacingSchema),
    typography: Type.Optional(typographySchema),
    border: Type.Optional(borderSchema),
    sizing: Type.Optional(Type.Pick(sizingSchema, ['width', 'height'])),
    hoverColor: OptionalString('Button Hover Color'),
    animationStates,
    styleAttr,
  },
  {
    dependencies: {
      href: {
        properties: {
          isExternal: Type.Boolean({
            default: false,
            title: 'Open Link in New Tab?',
          }),
        },
      },
    },
  }
);

export type SchemaType = Static<typeof schema> & {isExternal?: boolean};

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        ['children', 'aria-label', 'href', 'isExternal', 'hoverColor'],
      ],
      ['Animations', ['animationStates']],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [
              ['Background', ['background']],
              ['Typography', ['typography']],
              ['Border', ['border']],
              ['Spacing', ['spacing']],
              ['Sizing', ['sizing']],
            ],
          },
          'styleAttr',
        ],
      ],
    ],
  },
  ...styleAttrUi,
  ...animationUi,
  border: {
    borderColor: {
      'ui:widget': 'color',
    },
  },
  typography: {
    textColor: {
      'ui:widget': 'color',
    },
  },
  background: {
    buttonColor: {
      'ui:widget': 'color',
    },
  },
  spacing: {
    margin: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'margin',
      },
    },
    padding: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'padding',
      },
    },
  },
};

export const defaultFieldData: SchemaType = {
  children: 'Button Label',
  background: {
    buttonColor: '#EDF2F7',
  },
  typography: {
    textColor: '#000000',
  },
  hoverColor: '#EDF2F7',
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:click`,
    description: 'Click button (virtually)',
  }),
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-click`,
    description: 'Button has been clicked',
  }),
]);

// TODO: continue threading instruction definitions through the app so we can have type instruction type checking in components.

export const ComponentDefinition: ComponentAdmin = {
  id: '49615581-37a3-48ea-a547-fa4f7255e35e',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions,
  category,
};
