export {ButtonComponent} from './ButtonComponent';
export type {ButtonComponentDefinition} from './ButtonComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './ButtonDefinition';
export type {SchemaType} from './ButtonDefinition';
export {ButtonPreviewComponent} from './ButtonPreviewComponent';
export type {ButtonProps} from './ButtonPreviewComponent';

import {Registry} from '@backstage-components/base';
import {ButtonModule} from './ButtonModule';
import {ComponentDefinition} from './ButtonDefinition';
Registry.register(ComponentDefinition, ButtonModule);
